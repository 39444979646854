.header-name {
padding: 8px;
font-size: 24px;
margin-bottom: 1rem;
}
.filter-value {
  letter-spacing: 0.7px;
  font-style: italic;
  margin-left: 0.5rem;
  font-size: 16px;
}
