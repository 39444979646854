.srh {
  display: block;
  background-color: #F4F4F4;
  padding: 1.5rem 0;
}
.srh-found {
  font-size: 14px;
  font-weight: 400;
  line-height: 2.25rem;
  color: #6C5353;
  margin-left: 2rem
}

.pagination-container {
  padding: 1.5rem 0;
  text-align: center;
}

.helper-text {
  /* font-size: 0.8rem; */
  color: #7c7c7c;
  letter-spacing: 0.3px;
}
.dg {
  color: #868686;
}

.case-name {
  font-size: 18px;
  line-height: 2;
  letter-spacing: 1px;
  font-weight: bold;
}

.case-container {
  width: 95%;
  flex-direction: column;
  margin: 0.25rem 1.5rem;
  
}
.f-right {
  float: right;
  text-align: right;
}

.sm {
  font-size: 0.85rem;
}

.lh2{
  line-height: 2;
}
.mt-lh {
  margin-top: 1.5lh;
}
.files-container {
  width: 95%;
  margin: 1rem 2.25rem;
  display: flex;
  flex-direction: column;
}
.file-header{
  display: flex;
  flex-direction: column;
}
.muted-bckg {
  background-color: #FAFAFA;
}
.inline {
  display: inline;
}

.filename {
  font-size: 1.2rem;
  line-height: 1.5;
  letter-spacing: 0.5px;
  margin: 0.5rem 0;
  text-decoration: underline;
  display: inline;
}
.file-res {
  padding: 4px 0;
  cursor: pointer;
  width: fit-content;
  font-size: 19px;
}
.file-text {
  color: #2660b5;
}
.file-res:hover {
  text-decoration: underline;
}
.file-index {
  font-size: 18px;
  margin-right: 2rem;
  font-weight: bold;
}
.ml3 {
  margin-left: 3rem;
}

.paragraph-data {
  margin-left: 3rem;
  margin-top: 4px;
  line-height: 1.5;
}

.paragraph-data-unit > p {
  margin: 4px 0;
  line-height: 1.2;
  color: #2B2B2B;
}

.paragraph-data-unit {
  margin: 0.5rem 0;
}
.par-index{
  color: #848484;
  margin-right: 6px;
  font-size: 12px;
  width: 5rem;
  margin-left: -2rem;
}
.par-index::before {
  content: "\00B6"; /* Unicode character for paragraph sign */
  display: inline-block;
  margin-right: 3px; /* Adjust the spacing as needed */
  color: #848484;
  font-size: 12px;
}
.als-ctr {
  align-self: center;
}
.paragraph-text{
  text-align: justify;
  color: #2B2B2B;
  font-size: 13px;
  letter-spacing: 0;
}
.parsed-doc-view-btn {
  font-size: 11px;
  cursor: pointer;
  color: #adadad; 
  display: none;
}

.displ-parsed:hover > .parsed-doc-view-btn {
  display: inherit;
}