p.footer-main {
    margin-top: 4px;
}
.footer-main {
    color: var(--color-black);
    font-size: 12px;
    line-height: 18px;
    margin: 0;
    text-decoration: unset;
}


.footer {
    background-color: #f1f4f9;;
    left: 0;
    position: fixed;
    right: 0;
    bottom: 0;
}