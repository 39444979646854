.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.ml{
  margin-left: 8px;
}
.ml-2{
  margin-left: 12px;
}
.ml-3{
  margin-left: 16px;
}

.fnt-sz-14{
  font-size: 15px;
}

.fnt-sz-18{
  font-size: 18px;
}
.semibold {
  font-weight: 600;
}
.App-link {
  color: #61dafb;
}

.gradient-bckg {
  background: radial-gradient(circle, rgb(209 217 249) 0%, rgba(241,244,249,1) 100%, rgba(241,244,249,1) 100%);
}

.bckg-white {
  background-color: #fff;
}
.link-style {
  text-decoration: underline;
  color: rgb(56, 56, 218);
  cursor: pointer;
}
.link-style-on-hover {
  cursor: pointer;
  color: grey
}
.link-style-on-hover:hover {
  text-decoration: underline;
  color: rgb(56, 56, 218);
  cursor: pointer;
}

.d-block {
  display: block;
}
.walkstep {
  margin-bottom: 1rem;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

