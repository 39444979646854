.parsed-text {
  padding: 2rem 7%;
  line-height: 1.8;
  text-align: justify;
}
.parsed-text > p {
  margin-top: 0.9rem;
  color: #2a2a2a;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased !important;
  -moz-font-smoothing: antialiased !important;
  text-rendering: optimizelegibility !important;
}