.prof-item {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  hyphens: auto;
  word-break: break-word;
  word-wrap: break-word;
  padding: 12px;
  min-width: 200px;
}
.prof-item-header {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  hyphens: auto;
  word-break: break-word;
  word-wrap: break-word;
  padding: 16px;
  min-width: 200px;
}
.ml-a {
  margin-left: auto!important;
}
